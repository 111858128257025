'use client';

import { useMemo } from 'react';
import { COMMON_UI_UTILS, ENUM_UTILS, MarketId } from '@drift/common';
import useMarketStateStore from 'src/stores/useMarketStateStore';
import { TRADE_PREP_UTILS } from 'src/stores/DriftStore/actions/actionHelpers/orderPrep';
import { PositionDirection } from '@drift-labs/sdk';
import {
	PriceImpactInfo,
	useTradeformPriceImpact,
} from 'src/hooks/usePriceImpact';
import useGetOraclePriceForMarket from 'src/hooks/useGetOraclePriceForMarket';
import { syncGetCurrentSettings } from 'src/environmentVariables/EnvironmentVariables';
import { SlippageTolerance } from 'src/@types/types';

const useGetSlippageForMarket = (): ((
	marketId: MarketId,
	slippageTolerance: SlippageTolerance,
	direction: PositionDirection,
	priceImpact?: PriceImpactInfo
) => number) => {
	const getMarketDataForMarket = useMarketStateStore(
		(s) => s.getMarketDataForMarket
	);

	const getMarketTradePriceData = useMemo(
		() => (marketId: MarketId) =>
			getMarketDataForMarket(marketId)?.derivedState,
		[getMarketDataForMarket]
	);

	const tradeformPriceImpact = useTradeformPriceImpact();
	const getOraclePrice = useGetOraclePriceForMarket();

	const getSlippageForMarket = (
		marketId: MarketId,
		slippageTolerance: SlippageTolerance,
		direction: PositionDirection,
		priceImpact?: PriceImpactInfo // optionally pass in price impact info. otherwise, use tradeformPriceImpact if we confirm marketId matches.
	): number => {
		if (slippageTolerance == undefined || marketId == undefined) {
			return undefined;
		}

		const priceImpactToUse = priceImpact ?? tradeformPriceImpact;

		if (
			!priceImpactToUse ||
			priceImpactToUse.marketIndex !== marketId.marketIndex ||
			!ENUM_UTILS.match(priceImpactToUse.marketType, marketId.marketType)
		) {
			return undefined;
		}

		const derivedState = getMarketTradePriceData(marketId);

		const priceObject = COMMON_UI_UTILS.getPriceObject({
			oraclePrice: getOraclePrice(marketId)?.val,
			markPrice: derivedState?.markPrice?.val,
			entryPrice: priceImpactToUse.entryPrice,
			bestOffer: priceImpactToUse.bestPrice,
			worstPrice: priceImpactToUse.worstPrice,
			direction,
		});

		const userSettings = syncGetCurrentSettings();

		const startPriceFromSettings =
			priceObject[
				TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffsetFrom(
					userSettings.auctionStartPriceOffsetFrom,
					marketId
				)
			];

		if (slippageTolerance === 'dynamic') {
			return TRADE_PREP_UTILS.calculateDynamicSlippage(
				derivedState,
				marketId,
				startPriceFromSettings,
				priceImpactToUse.worstPrice
			);
		}

		return slippageTolerance as number;
	};

	return getSlippageForMarket;
};

export default useGetSlippageForMarket;
