'use client';

import { Close, Info } from '@drift-labs/icons';
import {
	BASE_PRECISION_EXP,
	BigNum,
	PositionDirection,
	PRICE_PRECISION_EXP,
	MarketType,
	TEN_THOUSAND,
	QUOTE_PRECISION_EXP,
	PerpMarketConfig,
	PerpMarketAccount,
	ContractType,
	MAX_PREDICTION_PRICE,
	OrderType,
	MAX_LEVERAGE_ORDER_SIZE,
	ZERO,
} from '@drift-labs/sdk';
import {
	OpenPosition,
	UIOrderType,
	UI_ORDER_TYPES_LIST,
	UI_ORDER_TYPES,
	matchEnum,
	ENUM_UTILS,
	UIOrderTypeValue,
	UIMarket,
	COMMON_UI_UTILS,
} from '@drift/common';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'src/components/Button';
import Select from 'src/components/Inputs/Select';
import TextField from 'src/components/Inputs/TextField';
import Slider from 'src/components/Slider';
import InfoMessage from 'src/components/TradeForm/InfoMessage';
import MarketIcon from 'src/components/Utils/MarketIcon';
import Env, {
	CurrentPerpMarkets,
	DEFAULT_MARKET_AUCTION_DURATION,
	syncGetCurrentSettings,
} from 'src/environmentVariables/EnvironmentVariables';
import useAccountData from 'src/hooks/useAccountData';
import useDriftActions from 'src/hooks/useDriftActions';
import useClosePositionStateEngine from 'src/hooks/useClosePositionStateEngine';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountsStore from 'src/stores/useDriftAccountsStore';
import NumLib from 'src/utils/NumLib';
import UI_UTILS from 'src/utils/uiUtils';
import { invertPriceImpact } from '../../hooks/usePriceImpact';
import useShowLiquidityWarning from '../../hooks/useShowLiquidityWarnings';
import Tooltip from '../Tooltip/Tooltip';
// import SlippageTolerance from '../TradeForm/SlippageTolerance';
import TradeformLiquidityWarning from '../TradeformLiquidityWarning';
import ValueDisplay from '../ValueDisplay';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useMarketsInfoStore from '../../stores/useMarketsInfoStore';
import useCurrentSettings from '../../hooks/useCurrentSettings';
import useMemoizedOraclePrice from 'src/hooks/useMemoizedOraclePrice';
import CheckboxInput from '../CheckboxInput';
import useDevSwitchIsOn from 'src/hooks/useDevSwitchIsOn';
import { twMerge } from 'tailwind-merge';
import useDriftClient from 'src/hooks/useDriftClient';
import useMarkPrice from 'src/hooks/useMarkPrice';
import PriceDivergenceWarningMessage from '../PriceDivergenceWarningMessage';
import Text from '../Text/Text';
import Utility from '../Inputs/Utility';
import { useMarketStepSize } from 'src/hooks/useMarketStepSize';
import SlippageToleranceDisplay from '../TradeForm/SlippageToleranceDisplay';
import { TradeFormMessageType } from 'src/@types/types';
import FormInputBox from '../Form/FormInputBox';
import PopoverWrapper, { FloatingPopoverProps } from '../PopoverWrapper';
import {
	MAX_PREDICTION_PRICE_BIGNUM,
	MAX_PREDICTION_PRICE_NUM,
} from 'src/constants/math';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import { InvariantChecker } from 'src/utils/UIInvariants/InvariantChecker';
import Chevron from '../Icons/Chevron';
import { PreviewOrderParams } from '../TradeForm/useTradeFormPreviewOrder';
import {
	AuctionPriceTooltip,
	SlippageTooltip,
} from '../TradeForm/PriceTooltips';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import useGetSlippageForMarket from '../TradeForm/useGetSlippageForMarket';
import { TRADE_PREP_UTILS } from 'src/stores/DriftStore/actions/actionHelpers/orderPrep';
import { useMarketOracleEnabledForSignedMsgOrders } from 'src/hooks/signedMsgOrders/useMarketOracleEnabledForSignedMsgOrders';
import { SwiftToggleSwitch } from '../TradeForm/SwiftToggleSwitch';
import { useSignedMsgOrdersEnablementStatus } from 'src/hooks/signedMsgOrders/useSignedMsgOrdersEnablementStatus';

type ClosePositionPopupProps = {
	marketIndex?: number;
	onClose: () => void;
	uiOrderType?: UIOrderType;
	isOverviewPage?: boolean;
	isTakeProfitOnly?: boolean;
	isStopLossOnly?: boolean;
	showHeader?: boolean;
	className?: string;
} & FloatingPopoverProps;

export const ORDER_TYPE_SELECT_ID = 'closePositionOrderTypeSelect';

const DISPLAY_EST_PNL_ORDER_TYPES: UIOrderType[] = [
	'market',
	'limit',
	'stopMarket',
	'stopLimit',
	'takeProfitMarket',
	'takeProfitLimit',
];

/*
 * Components
 */

const EstimatedRealizedPnL = ({
	selectedOrderType,
	takerFeePct,
	positionEntryPrice,
	tickSizeExponent,
	exitPrice,
	realisedPnl,
	pnlBeforeFees,
	notionalSizeAtExit,
	notionalSizeAtEntry,
	takerFee,
	isPredictionMarket,
}: {
	selectedOrderType: UIOrderType;
	takerFeePct: number;
	positionEntryPrice: BigNum;
	tickSizeExponent: number;
	exitPrice: string;
	realisedPnl: number;
	pnlBeforeFees: number;
	notionalSizeAtExit: number;
	notionalSizeAtEntry: number;
	takerFee: number;
	isPredictionMarket: boolean;
}) => {
	const ESTIMATED_EXIT_PRICE_ORDER_TYPES = [
		'market',
		'stopMarket',
		'takeProfitMarket',
	];

	const [expandedPriceDetails, setExpandedPriceDetails] = useState(false);

	const toggleExpandPriceDetails = () => {
		setExpandedPriceDetails((prev) => !prev);
	};

	return (
		<div onClick={toggleExpandPriceDetails} className="cursor-pointer">
			<ValueDisplay.Default
				label={
					<span className="flex items-center text-text-secondary">
						<div>
							{ESTIMATED_EXIT_PRICE_ORDER_TYPES.includes(selectedOrderType)
								? `Est. P&L`
								: 'Realized P&L (if filled)'}
						</div>
					</span>
				}
				value={
					<div className="flex flex-row items-center space-x-1">
						<div
							className={`font-numeral ${
								realisedPnl >= 0 ? 'text-positive-green' : 'text-negative-red'
							}`}
						>{`${UI_UTILS.toNotional(realisedPnl)}`}</div>
						<Chevron direction={expandedPriceDetails ? 'down' : 'up'} />
					</div>
				}
			/>

			{/* P&L Breakdown: */}
			<div
				className={twMerge(
					'relative ml-4 overflow-hidden transition-all duration-500',
					expandedPriceDetails ? 'mt-2 max-h-[120px]' : 'max-h-[0px]'
				)}
			>
				<div className="space-y-2">
					<ValueDisplay.Default
						label={
							<span className="flex items-center text-text-secondary">
								<div>Entry Price</div>
							</span>
						}
						value={
							<div
								className={`font-numeral`}
							>{`$${UI_UTILS.prettyPrintPriceBasedOnTick(
								positionEntryPrice,
								tickSizeExponent
							)}`}</div>
						}
					/>
					<ValueDisplay.Default
						label={
							<span className="flex items-center text-text-secondary">
								<div>Notional Size at Entry</div>
							</span>
						}
						value={
							<div className={`font-numeral`}>{`${UI_UTILS.toNotional(
								notionalSizeAtEntry
							)}`}</div>
						}
					/>
					<ValueDisplay.Default
						label={
							<span className="flex items-center text-text-secondary">
								<div>
									{ESTIMATED_EXIT_PRICE_ORDER_TYPES.includes(
										selectedOrderType
									) && 'Est. '}
									Notional Size at Exit
								</div>
							</span>
						}
						value={
							<div className={`font-numeral`}>{`${UI_UTILS.toNotional(
								notionalSizeAtExit
							)}`}</div>
						}
					/>

					{!isPredictionMarket && (
						<Tooltip
							allowHover
							showArrow
							placement="right"
							content={
								<>
									<span>
										{ESTIMATED_EXIT_PRICE_ORDER_TYPES.includes(
											selectedOrderType
										)
											? `Estimated realised P&L based on an estimated exit price of ${exitPrice}.`
											: `Realized P&L if order is completely filled at selected limit price`}
									</span>
								</>
							}
						>
							<ValueDisplay.Default
								label={
									<span className="flex items-center text-text-secondary">
										<div>
											{ESTIMATED_EXIT_PRICE_ORDER_TYPES.includes(
												selectedOrderType
											) && 'Est. '}
											P&L Before Fees
										</div>
										<Info size={14} className="relative left-1" />
									</span>
								}
								value={
									<div
										className={`font-numeral ${
											pnlBeforeFees >= 0
												? 'text-positive-green'
												: 'text-negative-red'
										}`}
									>{`${UI_UTILS.toNotional(pnlBeforeFees)}`}</div>
								}
							/>
						</Tooltip>
					)}

					<ValueDisplay.Default
						label={
							<span className="flex items-center text-text-secondary">
								<div>Taker Fee ({takerFeePct}%)</div>
							</span>
						}
						value={
							<div className={`font-numeral`}>
								~ {UI_UTILS.toNotional(takerFee)}
							</div>
						}
					/>
				</div>
			</div>
		</div>
	);
};

const ClosePositionPopup = (props: ClosePositionPopupProps) => {
	const closePositionPopupOptions = useDriftStore(
		(s) => s.popups.closePositionPopupOptions
	);

	return (
		<>
			<PopoverWrapper
				ref={props.setFloating}
				style={props.floatingStyles}
				{...props.getFloatingProps()}
			>
				<ClosePositionPopupInner {...props} {...closePositionPopupOptions} />
			</PopoverWrapper>
			<div className="absolute top-0 left-0 z-40 w-full h-full"></div>
		</>
	);
};

const ClosePositionHeader = ({
	uiMarket,
	price,
	isMarkPrice,
	marketSymbol,
	size,
	headerText,
	onClose,
	side,
}: {
	uiMarket: UIMarket;
	price: BigNum;
	isMarkPrice: boolean;
	marketSymbol: string;
	size: BigNum;
	headerText: React.ReactNode | string;
	onClose: () => void;
	side: 'buy' | 'sell';
}) => {
	return (
		<div className="flex flex-col w-full gap-3 bg-button-disabled">
			<div className="flex items-center justify-between w-full">
				<Text.BODY1 className="text-text-emphasis">{headerText}</Text.BODY1>
				<span onClick={onClose} className="hidden cursor-pointer sm:block">
					<Close size={18} color="var(--text-label)" />
				</span>
			</div>

			<div className="flex items-center justify-between w-full gap-2">
				<div className="flex items-center gap-2">
					<MarketIcon marketSymbol={marketSymbol} sizeClass="w-6 h-6" />
					<div className="flex flex-col">
						<Text.BODY2 className="text-text-default">
							{size.abs().prettyPrint()}{' '}
							{uiMarket.isPredictionMarket ? (
								<>
									<span>Shares</span>
									<span
										className={twMerge(
											'ml-1',
											side === 'sell'
												? 'text-positive-green'
												: 'text-negative-red'
										)}
									>
										{/** Use the opposite side to represent the current position */}
										(Bet {side === 'sell' ? 'Yes' : 'No'})
									</span>
								</>
							) : null}
						</Text.BODY2>
						<Text.MICRO3 className="text-text-label">
							{uiMarket.isPredictionMarket
								? PredictionMarketConfigs.get(uiMarket.marketIndex).shortTitle
								: marketSymbol}
						</Text.MICRO3>
					</div>
				</div>

				<div className="flex flex-col items-end text-right">
					<Text.BODY2 className="text-text-default">
						{price.toNotional(true)}
					</Text.BODY2>
					<Text.MICRO3 className="text-text-label whitespace-nowrap">
						{isMarkPrice ? 'Mark' : 'Oracle'} Price
					</Text.MICRO3>
				</div>
			</div>
		</div>
	);
};

/**
 * Need to split component into seperate "inner" and "outer" parts, because the TargettedPopup re-rendering on state change is causing the slider not to work (render cycle interrupts the drag event, I think because we're doing some pretty crazy styling to get the popup in the right position in the page - not sure how else to overcome the problem)
 * @param props
 * @returns
 */
export const ClosePositionPopupInner = (
	props: Omit<
		ClosePositionPopupProps,
		'ref' | 'getFloatingProps' | 'floatingStyles'
	>
) => {
	const selectedMarketIndex = props.marketIndex;
	const uiMarket = UIMarket.createPerpMarket(selectedMarketIndex);
	const marketId = uiMarket.marketId;

	const selectMarketInfo = useMarketsInfoStore((s) =>
		s.getMarketInfoByIndexAndType(marketId.marketIndex, marketId.marketType)
	);

	// Utility App Hooks

	const devSwitchIsOn = useDevSwitchIsOn();
	const { captureEvent } = usePostHogCapture();
	const actions = useDriftActions();
	const [currentSettings, updateSettings] = useCurrentSettings();
	const driftClient = useDriftClient();

	const setStore = useDriftStore((s) => s.set);
	const setAccountStore = useDriftAccountsStore((s) => s.set);
	const getSlippageForMarket = useGetSlippageForMarket();

	// Logic/State App Hooks

	const targetMarketInfo = useMarketsInfoStore((s) =>
		s.getMarketInfoByIndexAndType(props.marketIndex, MarketType.PERP)
	);

	const isPredictionMarket = ENUM_UTILS.match(
		(targetMarketInfo.account as PerpMarketAccount).contractType,
		ContractType.PREDICTION
	);

	const userAccount = useAccountData();
	const openOrders = useDriftAccountsStore(
		(s) => s.accounts[s.currentUserKey]?.openOrders || []
	);
	const openPositionInfo = useAccountData().openPerpPositions.find(
		(position) => position.marketIndex === props.marketIndex
	);
	const stepSize = useMarketStepSize(marketId);

	const tradeFormSlippageTolerance = useDriftStore(
		(s) => s.tradeForm.slippageTolerance
	);

	const openPositionSizeBigNum = BigNum.from(
		openPositionInfo.baseSize,
		BASE_PRECISION_EXP
	);

	const maxBaseSizeString = UI_UTILS.roundToStepSizeIfLargeEnough(
		openPositionSizeBigNum.abs().toNum().toString(),
		stepSize
	);
	const side = openPositionInfo.direction === 'long' ? 'sell' : 'buy';

	const isSellPredictionMarketPosition = useDriftStore((s) =>
		s.checkIsSellPredictionMarket({
			isPredictionMarket: uiMarket.isPredictionMarket,
			isSellSide: openPositionInfo.direction === 'short',
		})
	);
	const isSellPredictionMarketTradeForm = useDriftStore((s) =>
		s.checkIsSellPredictionMarket({
			isPredictionMarket: uiMarket.isPredictionMarket,
			isSellSide: side === 'sell',
		})
	);

	// Component States

	const [awaitingTx, setAwaitingTx] = useState(false);
	const [selectedOrderType, setSelectedOrderType] = useState(
		props.uiOrderType ??
			(props.isTakeProfitOnly
				? UI_ORDER_TYPES.takeProfitMarket.value
				: props.isStopLossOnly
				? UI_ORDER_TYPES.stopMarket.value
				: UI_ORDER_TYPES.market.value)
	);

	const oraclePrice = useMemoizedOraclePrice(
		UI_UTILS.getPerpMarketId(targetMarketInfo.config as PerpMarketConfig)
	);
	const currentMarkPrice = useMarkPrice(marketId);
	const markPriceToUse = isSellPredictionMarketPosition
		? MAX_PREDICTION_PRICE_BIGNUM.sub(currentMarkPrice)
		: currentMarkPrice;

	// Input States

	// source of truth for base size input
	const [baseSizeBoxStringValue, _setBaseSizeBoxStringValue] = // use handleBaseSizeBoxStringValueUpdate
		useState(maxBaseSizeString);
	// slider percentage is used purely for UI purposes
	const [sliderPercentage, setSliderPercentage] = useState(100);

	const [priceBoxStringValue, setPriceBoxStringValue] = useState(
		markPriceToUse?.toTradePrecision() ?? ''
	);
	const [secondaryPriceBoxStringValue, setSecondaryPriceBoxStringValue] =
		useState(markPriceToUse?.toTradePrecision() ?? '');

	const [hasAgreedToPriceDivergence, setHasAgreedToPriceDivergence] =
		useState(false);

	const [isSignedMsgOrderSelected, setIsSignedMsgOrderSelected] =
		useState(false);

	const [previewOrderParams, setPreviewOrderParams] =
		useState<PreviewOrderParams>();

	// init exit price to mark price, keep it updated as halfway through the auction
	const [exitPrice, setExitPrice] = useState(markPriceToUse);

	// prevents infinite loop between slider and baseSizeBox updates
	const lastUserInputMutex = useRef<'text' | 'slider'>('text');
	const initialPriceHasBeenSet = useRef(false);

	// Derived States

	const openOrdersForMarket = openOrders.filter(
		(order) =>
			order.marketIndex === props.marketIndex &&
			matchEnum(order.marketType, MarketType.PERP)
	);

	const targetMarketTickSizeString = BigNum.from(
		(targetMarketInfo.account as PerpMarketAccount).amm.orderTickSize,
		PRICE_PRECISION_EXP
	).prettyPrint();
	const targetMarketTickSizeExponent =
		targetMarketInfo.genericInfo.tickSizeExponent;

	const userAccountClient = userAccount.client;
	const { leverage, collateral } = userAccount.marginInfo;

	const oraclePriceNum = oraclePrice.toNum();
	const isOraclePrice = selectedOrderType === 'oracleLimit';

	const priceBoxValue = Number(priceBoxStringValue);
	const secondaryPriceBoxValue = Number(secondaryPriceBoxStringValue);

	const baseAmountOfCloseBigNum = BigNum.fromPrint(
		baseSizeBoxStringValue,
		BASE_PRECISION_EXP
	);
	const baseAmountOfCloseBN = baseAmountOfCloseBigNum.val;
	const currentPositionEntryPrice = BigNum.from(
		isSellPredictionMarketPosition
			? MAX_PREDICTION_PRICE.sub(openPositionInfo.entryPrice)
			: openPositionInfo.entryPrice,
		PRICE_PRECISION_EXP
	);

	const indexPricePnl = NumLib.formatBn.fromQuote(openPositionInfo.pnlVsOracle);

	const baseAssetSymbol = CurrentPerpMarkets.find(
		(market) => market.marketIndex === selectedMarketIndex
	).baseAssetSymbol;

	const priceStepIncrement = UI_UTILS.getInputPriceStepIncrement(
		markPriceToUse.toNum()
	);

	const { takerFeeBps, takerFeePct } = UI_UTILS.getMarketFees(
		userAccountClient,
		MarketType.PERP,
		selectedMarketIndex,
		driftClient
	);

	const {
		errorMessageInfo,
		//exitPrice,
		realisedPnl,
		pnlBeforeFees,
		notionalSizeAtEntry,
		notionalSizeAtExit,
		takerFee,
		//exitPriceNum,
		//estPriceImpact,
		liquidationPrice,
		showSecondaryPriceBox,
		priceBoxHeading,
		quoteSizeOfClose,
		priceImpactInfo,
	} = useClosePositionStateEngine({
		market: openPositionInfo.marketIndex,
		selectedOrderType: selectedOrderType,
		priceBoxValue: priceBoxValue,
		secondaryPriceBoxValue: secondaryPriceBoxValue,
		isLong: openPositionInfo.direction !== 'long',
		currentPositionDirection: openPositionInfo.direction,
		openPositionInfo,
		targetMarketTickSizeExponent,
		takerFeeBps,
		baseAmountOfClose: baseAmountOfCloseBigNum,
		user: userAccountClient,
	});

	const leverageAfterTrade = `${Math.max(
		NumLib.formatBn.toRawNum(
			userAccountClient.accountLeverageRatioAfterTrade(
				selectedMarketIndex,
				MarketType.PERP,
				quoteSizeOfClose,
				// Closing a position is always the opposite direction to the current trade direction
				openPositionInfo.direction === 'long'
					? PositionDirection.SHORT
					: PositionDirection.LONG
			),
			TEN_THOUSAND
		),
		0
	).toLocaleString(Env.locale, { maximumFractionDigits: 2 })}x`;

	const liquidityWarningState = useShowLiquidityWarning(
		selectedMarketIndex,
		baseAmountOfCloseBN,
		openPositionInfo.direction === 'long'
			? PositionDirection.SHORT
			: PositionDirection.LONG
	);

	const isPositionMarketSwiftEnabled = useMarketOracleEnabledForSignedMsgOrders(
		uiMarket.market.oracleSource
	);
	const isSignedMsgOrdersEnabled = useSignedMsgOrdersEnablementStatus();

	const canUseSignedMsgForClose =
		isPositionMarketSwiftEnabled &&
		isSignedMsgOrdersEnabled.status === 'enabled';
	const useSignedMsgForClose =
		canUseSignedMsgForClose && isSignedMsgOrderSelected;

	const estExitPriceDivergence =
		(Math.abs(exitPrice.toNum() - oraclePriceNum) / oraclePriceNum) * 100;

	const showDivergenceWarning =
		!isPredictionMarket &&
		selectedOrderType === 'market' &&
		estExitPriceDivergence > Env.priceDivergenceWarningThreshold &&
		estExitPriceDivergence < Infinity;

	const confirmButtonDisabled =
		awaitingTx ||
		baseAmountOfCloseBigNum.eqZero() ||
		(selectedOrderType !== 'market' && !priceBoxStringValue) ||
		(showSecondaryPriceBox && !secondaryPriceBoxStringValue) ||
		errorMessageInfo?.messageType === TradeFormMessageType.error ||
		!priceImpactInfo ||
		(showDivergenceWarning && !hasAgreedToPriceDivergence);

	/**
	 * Warning state for when the oracle price pnl is positive but the est. realized pnl is negative
	 */
	const realizedPnlMismatchWarningState =
		selectedOrderType === 'market' && indexPricePnl > 0 && realisedPnl < 0
			? {
					messageType: TradeFormMessageType.warn,
					messageTitle: `Est. realized P&L is negative due to the Est. Exit Price differing from the Oracle Price.`,
					messageId: 'realized-pnl-mismatch',
			  }
			: undefined;

	// useEffect Hooks

	// updates slider when base size input changes
	useEffect(() => {
		if (lastUserInputMutex.current === 'slider') {
			return;
		}

		const updatedSliderPercentage = +BigNum.fromPrint(
			baseSizeBoxStringValue,
			BASE_PRECISION_EXP
		).toPercentage(openPositionSizeBigNum.abs(), BASE_PRECISION_EXP.toNumber());

		if (updatedSliderPercentage < 0) {
			setSliderPercentage(0);
			return;
		} else if (updatedSliderPercentage > 99.99) {
			setSliderPercentage(100);
			return;
		}

		setSliderPercentage(updatedSliderPercentage);
	}, [baseSizeBoxStringValue]);

	// updates base size input when slider changes
	useEffect(() => {
		if (lastUserInputMutex.current === 'text') {
			return;
		}

		const scaledBaseSize = openPositionSizeBigNum.scale(sliderPercentage, 100);

		_setBaseSizeBoxStringValue(
			UI_UTILS.roundToStepSizeIfLargeEnough(
				scaledBaseSize.abs().toNum().toString(),
				stepSize
			)
		);
	}, [sliderPercentage, stepSize]);

	// set initial price input values on mark price change
	useEffect(() => {
		if (initialPriceHasBeenSet.current === false && !markPriceToUse.eqZero()) {
			const priceToUse = markPriceToUse.toTradePrecision();

			setPriceBoxStringValue(priceToUse);
			setSecondaryPriceBoxStringValue(priceToUse);
			initialPriceHasBeenSet.current = true;
		}
	}, [markPriceToUse?.toString()]);

	// track auction params. we can't use useTradeFormPreviewOrder here bc it relies on tradeform state.
	useEffect(() => {
		if (selectedOrderType !== 'market' && selectedOrderType !== 'oracle') {
			return;
		}

		const currentSettings = syncGetCurrentSettings();

		// return if prices/settings not loaded yet
		if (!currentSettings || !selectMarketInfo?.marketId) return;
		if (
			(!oraclePrice || oraclePrice?.eqZero()) &&
			(!currentMarkPrice || currentMarkPrice?.eqZero())
		)
			return;

		try {
			const slippage = getSlippageForMarket(
				marketId,
				tradeFormSlippageTolerance,
				side === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT,
				priceImpactInfo
			);

			const isCustomSlippage = UI_UTILS.isCustomSlippage(
				tradeFormSlippageTolerance,
				marketId
			);

			const auctionParams = COMMON_UI_UTILS.deriveMarketOrderParams({
				marketType: selectMarketInfo.marketId.marketType,
				marketIndex: selectMarketInfo.marketId.marketIndex,
				direction:
					side === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT,
				baseAmount: BigNum.fromPrint(baseSizeBoxStringValue, BASE_PRECISION_EXP)
					.val,
				reduceOnly: true,
				allowInfSlippage: tradeFormSlippageTolerance == undefined,
				worstPrice: priceImpactInfo.worstPrice,
				auctionDuration:
					currentSettings.auctionDuration ?? DEFAULT_MARKET_AUCTION_DURATION,
				auctionStartPriceOffset:
					TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffset(
						currentSettings.auctionStartPriceOffset,
						selectMarketInfo.marketId
					),
				auctionEndPriceOffset: currentSettings.auctionEndPriceOffset,
				maxLeverageSelected: false,
				maxLeverageOrderSize: MAX_LEVERAGE_ORDER_SIZE,
				oraclePrice: oraclePrice?.val,
				markPrice: currentMarkPrice?.val,
				bestPrice: priceImpactInfo.bestPrice,
				entryPrice: priceImpactInfo.entryPrice,
				auctionStartPriceOffsetFrom:
					TRADE_PREP_UTILS.getMarketBasedAuctionStartPriceOffsetFrom(
						currentSettings.auctionStartPriceOffsetFrom,
						selectMarketInfo.marketId
					),
				auctionEndPriceOffsetFrom: currentSettings.auctionEndPriceOffsetFrom,
				slippageTolerance: slippage,
				isOracleOrder: currentSettings.oracleOffsetOrdersEnabled,
				additionalEndPriceBuffer: UI_UTILS.getAdditionalEndPriceBuffer(
					selectMarketInfo.account as PerpMarketAccount
				),
				forceUpToSlippage: isCustomSlippage,
			});

			// if it was a limit order and not crossing, or auctions are disabled, return nothing
			if (!auctionParams?.auctionDuration) return;

			const isOracleOrder = ENUM_UTILS.match(
				auctionParams.orderType,
				OrderType.ORACLE
			);

			const params = {
				auctionStartPrice: isOracleOrder
					? oraclePrice?.val.add(auctionParams.auctionStartPrice)
					: auctionParams.auctionStartPrice,
				auctionEndPrice: isOracleOrder
					? oraclePrice?.val.add(auctionParams.auctionEndPrice)
					: auctionParams.auctionEndPrice,
				auctionDuration: auctionParams.auctionDuration,
				constrainedBySlippage: auctionParams.constrainedBySlippage,
				isOracleOrder,
				slippage,
			};

			setExitPrice(
				BigNum.from(
					params.auctionStartPrice.add(params.auctionEndPrice),
					PRICE_PRECISION_EXP
				).scale(1, 2)
			);

			setPreviewOrderParams(params);
		} catch (e) {
			console.error(`Error setting preview auction params: `, e);
		}
	}, [
		oraclePrice,
		currentMarkPrice,
		side,
		baseSizeBoxStringValue,
		selectedOrderType,
		priceImpactInfo,
		tradeFormSlippageTolerance,
		selectMarketInfo?.marketId?.key,
	]);

	// set initial price input values on order type change
	useEffect(() => {
		if (initialPriceHasBeenSet.current && !markPriceToUse.eqZero()) {
			const priceToUse = markPriceToUse.toTradePrecision();
			const offsetToUse =
				openPositionInfo.direction === 'long'
					? targetMarketTickSizeString
					: (-Number(targetMarketTickSizeString)).toString();

			setPriceBoxStringValue(
				selectedOrderType === 'oracleLimit' ? offsetToUse : priceToUse
			);
			setSecondaryPriceBoxStringValue(priceToUse);
		}
	}, [selectedOrderType]);

	const addOrderToAwaitingPnlDisplay = ({
		key,
		openPositionInfo,
		nextOrderId,
	}: {
		key: string;
		openPositionInfo: OpenPosition;
		nextOrderId: number;
	}) => {
		setStore((s) => {
			s.ordersAwaitingPnlModalDisplay = {
				...s.ordersAwaitingPnlModalDisplay,
				[key]: {
					openPositionInfo,
					orderId: nextOrderId,
				},
			};
		});
	};

	const handleClose = async () => {
		const currentIndex = openPositionInfo.marketIndex;

		setAccountStore((state) => {
			state.accounts[userAccount.userKey].pnlInfo = {
				leverageBeforeClose: leverage,
				collateralBeforeClose: collateral,
			};
		});

		setAwaitingTx(true);

		props.onClose();

		captureEvent('closing_position_from_popup');

		const result = await actions.openPerpTradeFormOrder(
			{
				side,
				baseSizeStringValue: baseSizeBoxStringValue,
				orderType: selectedOrderType,
				priceBoxStringValue: isSellPredictionMarketPosition
					? (MAX_PREDICTION_PRICE_NUM - +priceBoxStringValue).toFixed(
							QUOTE_PRECISION_EXP.toNumber()
					  )
					: priceBoxStringValue,
				secondaryPriceBoxStringValue: secondaryPriceBoxStringValue,
				reduceOnly: true,
				slippageTolerance: tradeFormSlippageTolerance,
				postOnly: false,
				targetMarketIndex: currentIndex,
				immediateOrCancel: false,
				priceImpact: isSellPredictionMarketTradeForm
					? invertPriceImpact(priceImpactInfo)
					: priceImpactInfo,
				oraclePrice: oraclePrice.val,
				markPrice: currentMarkPrice?.val,
				cancelExistingOrders:
					currentSettings.cancelExistingOrdersOnClosePosition,
				perpMarketAccount: selectMarketInfo.account as PerpMarketAccount,
				isSwiftSelected: useSignedMsgForClose,
			},
			'perp-close-position-popup'
		);

		setAwaitingTx(false);

		if (result !== false) {
			if (currentSettings.showPnlOnClose) {
				// Store the orderId w/ open position data as awaiting pnl modal display
				// We can check if the real Pnl that ended up getting filled was > 0 where we listen for that
				const nextOrderId = userAccountClient.getUserAccount().nextOrderId;
				const key = `${currentIndex}-${nextOrderId}`;
				addOrderToAwaitingPnlDisplay({ key, openPositionInfo, nextOrderId });
			}
		}
	};

	const handleSliderValueUpdate = (value: number) => {
		lastUserInputMutex.current = 'slider';
		setSliderPercentage(value);
	};

	const handleBaseSizeBoxUpdate = (value: string) => {
		lastUserInputMutex.current = 'text';

		const isGreaterThanMaxSize = BigNum.fromPrint(value, BASE_PRECISION_EXP).gt(
			openPositionSizeBigNum.abs()
		);

		if (isGreaterThanMaxSize) {
			const fitToStepSize = UI_UTILS.roundToStepSizeIfLargeEnough(
				openPositionSizeBigNum.abs().toNum().toString(),
				stepSize
			);
			_setBaseSizeBoxStringValue(fitToStepSize);
		} else {
			const fitToStepSize = UI_UTILS.roundToStepSizeIfLargeEnough(
				value,
				stepSize
			);
			_setBaseSizeBoxStringValue(fitToStepSize);
		}
	};

	const toggleCancelExistingOrdersOnClose = () => {
		updateSettings({
			cancelExistingOrdersOnClosePosition:
				!currentSettings.cancelExistingOrdersOnClosePosition,
		});
	};

	const getButtonCopy = () => {
		switch (selectedOrderType) {
			case 'market':
				return `Close ${BigNum.from(
					sliderPercentage
				).prettyPrint()}% of Position`;
			case 'limit':
				return 'Place Limit Order';
			case 'stopMarket':
			case 'stopLimit':
				return 'Place SL Order';
			case 'takeProfitMarket':
			case 'takeProfitLimit':
				return 'Place TP Order';
			case 'oracleLimit':
				return 'Place Oracle Limit Order';
			case 'scaledOrders':
				return 'Place Scaled Orders';
			default:
				throw new Error('Unhandled order type in close position popup button');
		}
	};

	let headerText: React.ReactNode;
	if (
		selectedOrderType === 'takeProfitMarket' ||
		selectedOrderType === 'takeProfitLimit' ||
		props.isTakeProfitOnly
	) {
		headerText = (
			<>
				Add TP for{' '}
				<span>{UI_UTILS.capitalizeWord(openPositionInfo.direction)}</span>{' '}
				Position
			</>
		);
	} else if (
		selectedOrderType === 'stopMarket' ||
		selectedOrderType === 'stopLimit'
	) {
		headerText = (
			<>
				Add SL for{' '}
				<span>{UI_UTILS.capitalizeWord(openPositionInfo.direction)}</span>{' '}
				Position
			</>
		);
	} else {
		headerText = (
			<>
				Close{' '}
				<span className="capitalize">
					{isPredictionMarket
						? 'Prediction Market'
						: openPositionInfo.direction}
				</span>{' '}
				Position
			</>
		);
	}

	const orderTypeOptions: UIOrderTypeValue[] =
		// Only allow market and limit orders for prediction markets
		isPredictionMarket
			? UI_ORDER_TYPES_LIST.filter((orderType) => {
					return orderType.value === 'market' || orderType.value === 'limit';
			  })
			: UI_ORDER_TYPES_LIST.filter((orderType) => {
					// No oracle orders when closing positions
					if (orderType.value === 'oracle') return false;
					if (orderType.value === 'scaledOrders') return false;

					if (props.isTakeProfitOnly) {
						return (
							orderType.value === 'limit' ||
							orderType.value === 'takeProfitMarket' ||
							orderType.value === 'takeProfitLimit'
						);
					}

					if (props.isStopLossOnly) {
						return (
							orderType.value === 'stopMarket' ||
							orderType.value === 'stopLimit'
						);
					}

					return true;
			  });

	return (
		<div
			className={twMerge(
				'flex flex-col [&>div]:p-3 pb-4 w-full sm:w-[360px]',
				props.className
			)}
			{...InvariantChecker.getInvariantCheckerContextWrapperHtmlAttributes(
				'perp-close-position-popup'
			)}
		>
			{props.showHeader !== false && (
				<ClosePositionHeader
					uiMarket={uiMarket}
					price={isOraclePrice ? oraclePrice : markPriceToUse}
					isMarkPrice={!isOraclePrice}
					marketSymbol={targetMarketInfo.genericInfo.marketSymbol}
					size={openPositionSizeBigNum}
					headerText={headerText}
					onClose={props.onClose}
					side={side}
				/>
			)}

			<div className="flex flex-col w-full">
				{/** Form inputs */}
				<div className="flex flex-col gap-3 mb-4">
					{/** Order Type Row */}
					<div className="flex items-center gap-2">
						<FormInputBox
							label="Order Type"
							className={twMerge(
								'max-w-[50%]',
								selectedOrderType === 'market' && 'pr-2'
							)}
						>
							<Select.Form
								id={ORDER_TYPE_SELECT_ID}
								currentSelection={selectedOrderType}
								options={orderTypeOptions}
								onChange={setSelectedOrderType}
								useFullWidth
							/>
						</FormInputBox>

						{/** Limit -> Limit | Stop/Stop Limit/Take Profit/Take Profit Limit -> Trigger Oracle Price | Oracle Limit -> Oracle Price Offset */}
						{selectedOrderType !== 'market' && (
							<FormInputBox label={priceBoxHeading}>
								<TextField.Default
									value={priceBoxStringValue}
									type="number"
									onChange={setPriceBoxStringValue}
									suffix={'USD'}
									stepAmount={priceStepIncrement}
								/>
							</FormInputBox>
						)}
					</div>

					{/** Limit Price Row */}
					{showSecondaryPriceBox && (
						<FormInputBox label="Limit Price">
							<TextField.Default
								value={secondaryPriceBoxStringValue}
								type="number"
								onChange={setSecondaryPriceBoxStringValue}
								suffix={'USD'}
								showIconForMarketSymbol={'USDC'}
								stepAmount={priceStepIncrement}
							/>
						</FormInputBox>
					)}

					{/** Size Fields Row */}
					<div className="flex items-center gap-2">
						<FormInputBox label="Size">
							<TextField.Default
								value={baseSizeBoxStringValue}
								type="number"
								onChange={handleBaseSizeBoxUpdate}
								showIconForMarketSymbol={
									isPredictionMarket ? undefined : baseAssetSymbol
								}
								suffix={isPredictionMarket ? 'Shares' : undefined}
							/>
						</FormInputBox>
						<FormInputBox
							quickAction={
								<div className="flex justify-end">
									<Text.BODY3
										className="flex justify-center px-1 rounded-sm bg-button-secondary-bg item-center text-text-secondary-button py-[2px] cursor-pointer"
										onClick={() => handleBaseSizeBoxUpdate(maxBaseSizeString)}
									>
										Max:{' '}
										{UI_UTILS.baseSizeDisplayForPerpMarket(
											openPositionSizeBigNum.abs(),
											openPositionInfo.marketSymbol,
											false
										)}
									</Text.BODY3>
								</div>
							}
						>
							<TextField.Default
								value={BigNum.from(
									quoteSizeOfClose,
									QUOTE_PRECISION_EXP
								).print()}
								type="number"
								disabled
								onChange={() => {}}
								showIconForMarketSymbol={'USDC'}
							/>
						</FormInputBox>
					</div>

					{/** Leverage Slider */}
					<div className="w-full text-text-emphasis">
						<Slider
							type="percent"
							disabled={null}
							value={sliderPercentage}
							onDrop={handleSliderValueUpdate}
							onMove={handleSliderValueUpdate}
							step={1}
							maxButtonTransition={false}
						/>
					</div>

					{/** Slippage Tolerance */}
					{selectedOrderType === 'market' && (
						<SlippageToleranceDisplay textClassName="text-text-secondary" />
					)}

					{/** Swift Toggle */}
					{canUseSignedMsgForClose && (
						<SwiftToggleSwitch
							value={isSignedMsgOrderSelected}
							onChange={setIsSignedMsgOrderSelected}
							className="px-0"
						/>
					)}
				</div>

				<Utility.VERTDIVIDER />

				{/** Est. Exit Price */}
				<div
					className={twMerge(
						'flex flex-col space-y-2',
						DISPLAY_EST_PNL_ORDER_TYPES.includes(selectedOrderType) && 'mt-4'
					)}
				>
					{selectedOrderType == 'market' && (
						<>
							{tradeFormSlippageTolerance === 'dynamic' ? (
								<SlippageTooltip
									placement="right"
									childElement={
										<ValueDisplay.Default
											label={
												<span>
													Dynamic Slippage
													<Info
														size={14}
														className="relative top-[3px] left-1"
													/>
												</span>
											}
											value={
												previewOrderParams?.slippage ? (
													<>
														{parseFloat(
															previewOrderParams?.slippage?.toFixed(2)
														)}
														%
													</>
												) : (
													<SkeletonValuePlaceholder
														className="h-[14px] w-[50px]"
														loading={Number(baseSizeBoxStringValue) > 0}
													/>
												)
											}
											rightPadding={'pt-[3px]'}
										/>
									}
								/>
							) : (
								<>
									<AuctionPriceTooltip
										placement="right"
										type="start"
										price={previewOrderParams?.auctionStartPrice ?? ZERO}
										isOracleOrder={previewOrderParams?.isOracleOrder}
										showConstrainedBySlippageWarning={false}
										childElement={
											<ValueDisplay.Default
												label={
													<span>
														Auction Start Price
														<Info
															size={14}
															className="relative top-[3px] left-1"
														/>
													</span>
												}
												value={
													previewOrderParams ? (
														<>
															{COMMON_UI_UTILS.trimTrailingZeros(
																UI_UTILS.toNotional(
																	BigNum.from(
																		previewOrderParams?.auctionStartPrice ??
																			ZERO,
																		PRICE_PRECISION_EXP
																	).toNum(),
																	5
																),
																2
															)}
														</>
													) : (
														<SkeletonValuePlaceholder
															className="h-[14px] w-[50px]"
															loading={Number(baseSizeBoxStringValue) > 0}
														/>
													)
												}
												rightPadding={'pt-[3px]'}
											/>
										}
									/>
									<AuctionPriceTooltip
										placement="right"
										type="end"
										price={previewOrderParams?.auctionEndPrice ?? ZERO}
										isOracleOrder={previewOrderParams?.isOracleOrder}
										showConstrainedBySlippageWarning={
											previewOrderParams?.constrainedBySlippage
										}
										childElement={
											<ValueDisplay.Default
												label={
													<div className="flex items-center">
														<span>
															{side === 'buy' ? 'Max' : 'Min'} Price
															<Info
																size={14}
																className="relative top-[3px] left-1"
															/>
														</span>
														{previewOrderParams?.constrainedBySlippage &&
															baseSizeBoxStringValue && (
																<div
																	className={`text-warn-yellow ml-2 px-1 py-0.5 space-x-2 border rounded text-xs`}
																	style={{
																		borderColor: 'var(--yellow-80)',
																	}}
																>
																	Low Slippage
																</div>
															)}
													</div>
												}
												value={
													previewOrderParams ? (
														<>
															{COMMON_UI_UTILS.trimTrailingZeros(
																UI_UTILS.toNotional(
																	BigNum.from(
																		previewOrderParams?.auctionEndPrice ?? ZERO,
																		PRICE_PRECISION_EXP
																	).toNum(),
																	5
																),
																2
															)}
														</>
													) : (
														<SkeletonValuePlaceholder
															className="h-[14px] w-[50px]"
															loading={Number(baseSizeBoxStringValue) > 0}
														/>
													)
												}
												rightPadding={'pt-[3px]'}
											/>
										}
									/>
								</>
							)}
						</>
					)}

					{/** Available Liquidity Warning */}
					{selectedOrderType == 'market' &&
						liquidityWarningState &&
						liquidityWarningState.state === 'show' && (
							<TradeformLiquidityWarning warningState={liquidityWarningState} />
						)}

					{/** New Estimated Liquidation Price - Dev mode */}
					{devSwitchIsOn && (
						<>
							<div className="flex items-center justify-between w-full space-x-6 text-xs text-text-default">
								<div className="text-text-secondary">
									New Estimated Liquidation Price{' '}
								</div>

								<div className="text-text-default font-numeral">
									{liquidationPrice}
								</div>
							</div>

							<div className="flex items-end justify-between w-full text-xs text-text-default">
								<div className="text-text-secondary">
									Account Leverage Ratio After Close
								</div>

								<div className="text-text-default font-numeral">
									{leverageAfterTrade}
								</div>
							</div>
						</>
					)}

					{/** Est. Realized P&L */}
					{DISPLAY_EST_PNL_ORDER_TYPES.includes(selectedOrderType) && (
						<EstimatedRealizedPnL
							selectedOrderType={selectedOrderType}
							takerFeePct={takerFeePct}
							positionEntryPrice={currentPositionEntryPrice}
							tickSizeExponent={targetMarketTickSizeExponent}
							exitPrice={exitPrice.print()}
							realisedPnl={realisedPnl}
							pnlBeforeFees={pnlBeforeFees}
							notionalSizeAtEntry={notionalSizeAtEntry}
							notionalSizeAtExit={notionalSizeAtExit}
							takerFee={takerFee}
							isPredictionMarket={isPredictionMarket}
						/>
					)}

					<div className="flex flex-col space-y-2 min-h-[36px]">
						{errorMessageInfo?.shouldShowMessage && (
							<div className="flex flex-col max-w-[320px]">
								<InfoMessage
									type={errorMessageInfo.messageType}
									// message={errorMessageInfo.message}
									// link={errorMessageInfo.messageLink}
									// linkDescription={errorMessageInfo.messageLinkDescription}
									messageTitle={errorMessageInfo.messageTitle}
								/>
							</div>
						)}
						{realizedPnlMismatchWarningState && (
							<div className="flex flex-col max-w-[320px]">
								<InfoMessage
									type={realizedPnlMismatchWarningState.messageType}
									messageTitle={realizedPnlMismatchWarningState.messageTitle}
								/>
							</div>
						)}
						{showDivergenceWarning && (
							<PriceDivergenceWarningMessage
								hasAgreedToPriceDivergence={hasAgreedToPriceDivergence}
								setHasAgreedToPriceDivergence={setHasAgreedToPriceDivergence}
								priceDivergencePct={estExitPriceDivergence.toFixed(1)}
							/>
						)}
						{selectedOrderType === 'market' &&
							openOrdersForMarket.length > 0 && (
								<div
									className="overflow-hidden transition-all duration-300"
									style={{
										maxHeight: sliderPercentage == 100 ? '20px' : '0px',
									}}
								>
									<CheckboxInput
										label={'Cancel open orders for this market'}
										onChange={toggleCancelExistingOrdersOnClose}
										checked={
											currentSettings.cancelExistingOrdersOnClosePosition
										}
										secondaryStyle
										className="text-text-default"
									/>
								</div>
							)}
					</div>
				</div>

				<Button.BigSemantic
					positive={realisedPnl >= 0}
					disabled={confirmButtonDisabled}
					onClick={handleClose}
				>
					{getButtonCopy()}
				</Button.BigSemantic>
			</div>
		</div>
	);
};

export default React.memo(ClosePositionPopup);
