'use client';

import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { DriftTheme } from '../../environmentVariables/EnvironmentVariables';
import useCurrentSettings from '../../hooks/useCurrentSettings';
import useDriftTheme from '../../hooks/useDriftTheme';
import { twMerge } from 'tailwind-merge';

const ValueChangeBlink = (
	props: PropsWithChildren<{ value: any; className?: string }>
) => {
	const [{ blinkOnValueChange: blinkEnabled }] = useCurrentSettings();

	const [prevValue, setPrevValue] = useState(props.value);

	const [flipBrightness, setFlipBrightness] = useState(false);

	const flipping = useRef(false);

	useEffect(() => {
		if (!blinkEnabled) return;
		if (props.value !== prevValue) {
			setPrevValue(props.value);
			if (flipping.current) return;

			flipping.current = true;
			setFlipBrightness(true);
			setTimeout(() => {
				setFlipBrightness(false);
				flipping.current = false;
			}, 150);
		}
	}, [prevValue, props.value, blinkEnabled]);

	const isLightTheme = useDriftTheme() === DriftTheme.light;

	return (
		<span
			className={twMerge(
				`transition-all ${
					flipBrightness
						? `brightness-50 ${isLightTheme ? 'opacity-30' : 'opacity-50'}`
						: 'brightness-100 opacity-100'
				}`,
				props.className
			)}
		>
			{props.children}
		</span>
	);
};

export default ValueChangeBlink;
