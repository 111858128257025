import { useRef, useState } from 'react';
import { useEffect } from 'react';
import SwiftClient from 'src/utils/swiftClient';
import { singletonHook } from 'react-singleton-hook';
import useFeatureFlagStore from 'src/stores/useFeatureFlagStore';

const useSwiftServerHealthCheckImpl = () => {
	const isSignedMsgOrdersEnabled = useFeatureFlagStore((s) =>
		s.flagEnabled('ENABLE_SIGNED_MSG_ORDERS')
	);
	const currentTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
	const [isHealthy, setIsHealthy] = useState(false);

	useEffect(() => {
		const checkHealth = async () => {
			const isSwiftHealthy = await SwiftClient.isSwiftServerHealthy();
			setIsHealthy(isSwiftHealthy);
			if (!isSwiftHealthy) {
				// when something is wrong with server, check more quickly to re-enable swift asap
				clearTimeout(currentTimeoutRef.current);
				currentTimeoutRef.current = setTimeout(checkHealth, 20_000);
			} else {
				// when server is healthy, check less frequently
				clearTimeout(currentTimeoutRef.current);
				currentTimeoutRef.current = setTimeout(checkHealth, 120_000);
			}
		};
		if (isSignedMsgOrdersEnabled) {
			checkHealth();
		}
	}, [isSignedMsgOrdersEnabled]);

	return isHealthy;
};

export const useSwiftServerHealthCheck = singletonHook(
	false,
	useSwiftServerHealthCheckImpl
);
