import { OracleSource } from '@drift-labs/sdk';
import { useMemo } from 'react';
import { ORACLE_SOURCES_ENABLED_FOR_SIGNED_MSG_ORDERS } from 'src/constants/signedOrderMsgs';

export function useMarketOracleEnabledForSignedMsgOrders(
	marketOracle: OracleSource
) {
	return useMemo(
		() => ORACLE_SOURCES_ENABLED_FOR_SIGNED_MSG_ORDERS.includes(marketOracle),
		[marketOracle]
	);
}
