import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import SwitchInput from '../Inputs/SwitchInput';
import { useSignedMsgOrdersEnablementStatus } from 'src/hooks/signedMsgOrders/useSignedMsgOrdersEnablementStatus';
import { SwiftLogo } from '../Icons/SwiftLogo';
import { useIsSignedMsgUserOrdersAccountInitialized } from 'src/hooks/signedMsgOrders/useIsSignedMsgUserOrdersAccountInitialized';
import useDriftClient from 'src/hooks/useDriftClient';
import { SwiftEnablementStatus } from 'src/@types/signedMsgOrders';
import { useEffect, useMemo } from 'react';
import { initializeSignedMsgUserOrdersAccount } from 'src/utils/signedMsgs';

const getSwiftLogoVariant = (
	checked: boolean,
	swiftEnablementStatus: SwiftEnablementStatus
) => {
	if (swiftEnablementStatus.status === 'disabled') {
		return 'disabled';
	}
	return checked ? 'active' : 'inactive';
};

export const SwiftToggleSwitch = ({
	value,
	className,
	onChange,
}: {
	value: boolean;
	className?: string;
	onChange: (checked: boolean) => void;
}) => {
	const wallet = useDriftStore((s) => s.wallet);
	const signedMsgOrdersEnablementStatus = useSignedMsgOrdersEnablementStatus();
	const driftClient = useDriftClient();

	const { data: isSignedMsgUserOrdersAccountInitialized, isLoading } =
		useIsSignedMsgUserOrdersAccountInitialized();

	const handleChange = async (isSignedMsgSelected: boolean) => {
		if (!isSignedMsgUserOrdersAccountInitialized && isSignedMsgSelected) {
			const isInitialized = await initializeSignedMsgUserOrdersAccount(
				driftClient,
				wallet.current.adapter
			);
			if (isInitialized) {
				onChange(isSignedMsgSelected);
			}
		} else {
			onChange(isSignedMsgSelected);
		}
	};

	useEffect(() => {
		if (isLoading) return;
		if (signedMsgOrdersEnablementStatus.status !== 'enabled' && value) {
			handleChange(false);
		} else if (
			signedMsgOrdersEnablementStatus.status === 'enabled' &&
			isSignedMsgUserOrdersAccountInitialized &&
			!value
		) {
			handleChange(true);
		} else if (!isSignedMsgUserOrdersAccountInitialized) {
			handleChange(false);
		}
	}, [
		signedMsgOrdersEnablementStatus,
		isSignedMsgUserOrdersAccountInitialized,
		isLoading,
	]);

	const tooltip = useMemo(() => {
		if (signedMsgOrdersEnablementStatus.status === 'disabled') {
			return signedMsgOrdersEnablementStatus.reason;
		}
		if (!value) {
			return 'Enable Swift for gasless Market orders.';
		}
	}, [signedMsgOrdersEnablementStatus]);

	return (
		<SwitchInput
			label={
				<SwiftLogo
					size="medium"
					variant={getSwiftLogoVariant(value, signedMsgOrdersEnablementStatus)}
				/>
			}
			labelClassName={className}
			checked={value}
			onChange={() => handleChange(!value)}
			disabled={signedMsgOrdersEnablementStatus.status === 'disabled'}
			tooltip={tooltip}
		/>
	);
};
