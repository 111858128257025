import useCurrentWalletAdapter from '../useCurrentWalletAdapter';
import useDriftClient from '../useDriftClient';
import useDriftClientIsReady from '../useDriftClientIsReady';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useIsSignedMsgUserOrdersAccountInitialized = (): UseQueryResult<
	boolean,
	Error
> => {
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const wallet = useCurrentWalletAdapter();

	return useQuery({
		queryKey: [
			'isSignedMsgUserOrdersAccountInitialized',
			wallet.publicKey?.toBase58(),
		],
		queryFn: () =>
			driftClient.isSignedMsgUserOrdersAccountInitialized(wallet.publicKey),
		enabled: !!wallet.publicKey && !!driftClientIsReady,
		staleTime: Infinity,
	});
};
