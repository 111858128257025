'use client';

import {
	AMM_RESERVE_PRECISION_EXP,
	BASE_PRECISION_EXP,
	BN,
	BigNum,
	PERCENTAGE_PRECISION_EXP,
	PRICE_PRECISION_EXP,
	PositionDirection,
	QUOTE_PRECISION_EXP,
	SpotBalanceType,
} from '@drift-labs/sdk';
import {
	ENUM_UTILS,
	UISerializableOrder,
	COMMON_UI_UTILS,
	MarketId,
	UIMarket,
} from '@drift/common';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'src/components/Button';
import TextField from 'src/components/Inputs/TextField';
import MarketIcon from 'src/components/Utils/MarketIcon';
import { MAX_PREDICTION_PRICE_BIGNUM, ZERO } from 'src/constants/math';
import useDriftActions from 'src/hooks/useDriftActions';
import useMarkPrice from 'src/hooks/useMarkPrice';
import useMaxAvailable from 'src/hooks/useMaxAvailable';
import { getPriceImpactForTargetMarket } from 'src/hooks/usePriceImpact';
import useWindowSizeString from 'src/hooks/useWindowSize';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import UI_UTILS from 'src/utils/uiUtils';
import Text from '../Text/Text';
import InfoMessage from '../TradeForm/InfoMessage';
import SlippageToleranceDisplay from '../TradeForm/SlippageToleranceDisplay';
import ValueDisplay from '../ValueDisplay';
import useDebouncedValue from 'src/hooks/utils/useDebouncedValue';
import useHotkey from 'src/hooks/useHotkey';
import useMarketStateStore from '../../stores/useMarketStateStore';
import useAccountData from 'src/hooks/useAccountData';
import useAccountSpotBalances from 'src/hooks/useAccountBankBalances';
import { matchEnum } from '@drift/common';
import useMemoizedOraclePrice from 'src/hooks/useMemoizedOraclePrice';
import EditOrderModal from '../Modals/EditOrderModal';
import { Close } from '@drift-labs/icons';
import FormInputBox from '../Form/FormInputBox';
import {
	getMessageInfoForOrderViolation,
	getPriceBoxHeading,
	getShouldShowSecondaryPriceBox,
} from 'src/utils/trade';
import { useMarketStepSize } from 'src/hooks/useMarketStepSize';
import Slider from '../Slider';
import { getQuotePriceToUse } from 'src/hooks/useClosePositionStateEngine';
import { twMerge } from 'tailwind-merge';
import PopoverWrapper, { FloatingPopoverProps } from '../PopoverWrapper';
import useL2StateForMarket from 'src/hooks/useL2StateForMarket';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useGetSlippageForMarket from '../TradeForm/useGetSlippageForMarket';

type EditOrderPopupInnerProps = {
	existingOrder: UISerializableOrder & { accountName?: string };
	onClose: () => void;
	newPrice?: BigNum;
};

type EditOrderPopupProps = EditOrderPopupInnerProps & FloatingPopoverProps;

const directionLabelsPerp = {
	long: 'Long',
	short: 'Short',
};

const directionLabelsSpot = {
	long: 'Buy',
	short: 'Sell',
};

const EditOrderPopup = (props: EditOrderPopupProps) => {
	const windowSize = useWindowSizeString();

	useHotkey('Escape', () => {
		props.onClose();
	});

	if (windowSize === 'xs') {
		return (
			<EditOrderModal
				onClose={props.onClose}
				existingOrder={props.existingOrder}
			/>
		);
	}

	return (
		<>
			<PopoverWrapper
				ref={props.setFloating}
				style={props.floatingStyles}
				{...props.getFloatingProps()}
			>
				<EditOrderPopupInner {...props} />
			</PopoverWrapper>
			<div className="absolute top-0 left-0 z-40 w-full h-full"></div>
		</>
	);
};

const EditOrderHeader = ({
	uiMarket,
	price,
	isMarkPrice,
	marketSymbol,
	size,
	headerText,
	onClose,
	stepSize,
	isLongOrder,
}: {
	uiMarket: UIMarket;
	price: BigNum;
	isMarkPrice: boolean;
	marketSymbol: string;
	size: BigNum;
	headerText: React.ReactNode | string;
	onClose: () => void;
	stepSize: number;
	isLongOrder: boolean;
}) => {
	const isPredictionMarket = uiMarket.isPredictionMarket;

	return (
		<div className="flex flex-col w-full gap-3 bg-button-disabled">
			<div className="flex items-center justify-between w-full">
				<Text.BODY1 className="text-text-emphasis">{headerText}</Text.BODY1>
				<span onClick={onClose} className="hidden cursor-pointer sm:block">
					<Close size={18} color="var(--text-label)" />
				</span>
			</div>

			<div className="flex items-center justify-between w-full">
				<div className="flex items-center gap-2">
					<MarketIcon marketSymbol={marketSymbol} sizeClass="w-6 h-6" />
					<div className="flex flex-col">
						<Text.BODY2 className="text-text-default">
							{UI_UTILS.roundToStepSizeIfLargeEnough(
								size.toNum().toString(),
								stepSize
							)}
							{isPredictionMarket && (
								<span>
									{' '}
									Shares
									<span
										className={twMerge(
											isLongOrder ? 'text-positive-green' : 'text-negative-red'
										)}
									>
										{' '}
										(Bet {isLongOrder ? 'Yes' : 'No'})
									</span>
								</span>
							)}
						</Text.BODY2>
						<Text.MICRO3 className="text-text-label">
							{isPredictionMarket
								? PredictionMarketConfigs.get(uiMarket.marketIndex).shortTitle
								: marketSymbol}
						</Text.MICRO3>
					</div>
				</div>

				<div className="flex flex-col items-end text-right">
					<Text.BODY2 className="text-text-default">
						{price.toNotional(true)}
					</Text.BODY2>
					<Text.MICRO3 className="text-text-label">
						{isMarkPrice ? 'Mark' : 'Oracle'} Price
					</Text.MICRO3>
				</div>
			</div>
		</div>
	);
};

export const EditOrderPopupInner = ({
	existingOrder,
	onClose,
	newPrice,
}: EditOrderPopupInnerProps) => {
	if (!existingOrder) return null;

	// Existing order derived values

	const marketType = existingOrder.marketType;
	const marketIndex = existingOrder.marketIndex;
	const marketId = new MarketId(marketIndex, marketType);
	const uiMarket = UIMarket.fromMarketId(marketId);
	const isSpot = uiMarket.isSpot;
	const side = ENUM_UTILS.match(
		existingOrder?.direction,
		PositionDirection.LONG
	)
		? 'buy'
		: 'sell';
	const orderIsLong = side === 'buy';
	const baseAssetSymbol = uiMarket.baseAssetSymbol();
	const existingOrderType = UI_UTILS.getUIOrderTypeFromSdkOrderType(
		existingOrder.orderType,
		existingOrder.triggerCondition,
		existingOrder.direction,
		existingOrder.oraclePriceOffset
	);
	const isSellPredictionMarket = useDriftStore((s) =>
		s.checkIsSellPredictionMarket({
			isPredictionMarket: uiMarket.isPredictionMarket,
			isSellSide: side === 'sell',
		})
	);
	const l2State = useL2StateForMarket(
		uiMarket.marketIndex,
		uiMarket.marketType
	);

	const existingOrderLimitPriceString = COMMON_UI_UTILS.trimTrailingZeros(
		(isSellPredictionMarket
			? MAX_PREDICTION_PRICE_BIGNUM.sub(existingOrder.price)
			: existingOrder.price
		)
			.toPrecision(QUOTE_PRECISION_EXP.toNumber())
			.toString()
	);
	const existingOrderOracleOffset = existingOrder.oraclePriceOffset
		? COMMON_UI_UTILS.trimTrailingZeros(
				existingOrder.oraclePriceOffset
					?.toPrecision(QUOTE_PRECISION_EXP.toNumber())
					.toString()
		  )
		: undefined;

	const existingOrderTriggerPriceString = COMMON_UI_UTILS.trimTrailingZeros(
		existingOrder.triggerPrice
			?.toPrecision(QUOTE_PRECISION_EXP.toNumber())
			.toString()
	);
	const existingOrderSizeStringValue = COMMON_UI_UTILS.trimTrailingZeros(
		existingOrder.baseAssetAmount
			?.sub(existingOrder?.baseAssetAmountFilled)
			.toTradePrecision()
	);

	const existingOrderDirectionPerp: string =
		directionLabelsPerp[
			Object.keys(
				existingOrder.direction
			)[0] as keyof typeof directionLabelsPerp
		] || '';
	const existingOrderDirectionSpot: string =
		directionLabelsSpot[
			Object.keys(
				existingOrder.direction
			)[0] as keyof typeof directionLabelsSpot
		] || '';

	const isAnyLimitOrder = ['limit', 'stopLimit', 'takeProfitLimit'].includes(
		existingOrderType.value
	);
	const isLimitOnlyOrder = existingOrderType?.value === 'limit';
	const isTriggerOrder = [
		'stopMarket',
		'stopLimit',
		'takeProfitMarket',
		'takeProfitLimit',
	].includes(existingOrderType.value);
	const isTriggerLimitOrder = ['stopLimit', 'takeProfitLimit'].includes(
		existingOrderType?.value
	);
	const isOracleOffsetOrder = existingOrderType.value === 'oracleLimit';
	const isMarketOnlyOrder = existingOrderType.value === 'market';
	const isMarketTpSlOrder = ['takeProfitMarket', 'stopMarket'].includes(
		existingOrderType.value
	);

	const showSecondaryPriceBox = getShouldShowSecondaryPriceBox(
		existingOrderType.value
	);
	const priceBoxHeading = getPriceBoxHeading(existingOrderType.value);

	const getInitialPrimaryPrice = () => {
		if (newPrice) {
			return newPrice.print();
		}

		if (isTriggerOrder) {
			return existingOrderTriggerPriceString;
		}

		if (isLimitOnlyOrder) {
			return existingOrderLimitPriceString;
		}

		if (existingOrderType.value === 'oracleLimit') {
			return existingOrderOracleOffset;
		}

		return '';
	};

	const getInitialSecondaryPrice = () => {
		if (isTriggerOrder) {
			return existingOrderLimitPriceString;
		}

		return '';
	};

	// Utility/Logic App Hooks

	const currentUserKey = useDriftAccountStore((s) => s.currentUserKey);
	const currentUserPerpPositions = useAccountData()?.openPerpPositions;
	const currentUserSpotBalances = useAccountSpotBalances(currentUserKey);
	const slippageTolerance = useDriftStore((s) => s.tradeForm.slippageTolerance);
	const actions = useDriftActions();
	const getState = useDriftStore((s) => s.get);
	const getDlobState = useMarketStateStore((s) => s.get);
	const getSlippageForMarket = useGetSlippageForMarket();

	const oraclePrice = useMemoizedOraclePrice(marketId);
	const stepSize = useMarketStepSize(marketId);
	const markPrice = useMarkPrice(marketId);
	const markPriceToUse = isSellPredictionMarket
		? MAX_PREDICTION_PRICE_BIGNUM.sub(markPrice)
		: markPrice;
	const openPositionInfo = useAccountData().openPerpPositions.find(
		(position) => position.marketIndex === marketIndex
	);
	const spotBalance = currentUserSpotBalances.find(
		(balance) => balance.asset.marketIndex === marketIndex
	);
	const currentPositionSide = isSpot
		? ENUM_UTILS.match(spotBalance?.balanceType, SpotBalanceType.DEPOSIT)
			? 'long'
			: 'short'
		: openPositionInfo?.direction;

	// Component React states
	// source of truth for base size input
	const [baseSizeStringValue, _setBaseSizeStringValue] = // use handleBaseSizeBoxStringValueUpdate instead
		useState(existingOrderSizeStringValue);
	// slider percentage is used purely for UI purposes
	const [sliderPercentage, setSliderPercentage] = useState(100);

	const [priceBoxStringValue, setPriceBoxStringValue] = useState(
		getInitialPrimaryPrice()
	);
	const [secondaryPriceBoxStringValue, setSecondaryPriceBoxStringValue] =
		useState(getInitialSecondaryPrice());

	const [submitting, setSubmitting] = useState(false);

	// React Refs

	// prevents infinite loop between slider and baseSizeBox updates
	const lastUserInputMutex = useRef<'text' | 'slider'>('text');

	// Derived states

	const priceBoxValueBigNum = BigNum.fromPrint(
		priceBoxStringValue,
		PRICE_PRECISION_EXP
	);
	const secondaryPriceBoxValueBigNum = BigNum.fromPrint(
		secondaryPriceBoxStringValue,
		PRICE_PRECISION_EXP
	);
	const limitPriceStringValue = isTriggerLimitOrder
		? secondaryPriceBoxStringValue
		: isLimitOnlyOrder
		? priceBoxStringValue
		: '';

	const baseSizeBigNum = BigNum.fromPrint(
		baseSizeStringValue,
		existingOrder.baseAssetAmount.precision
	);

	const isSizeEdited = !baseSizeBigNum.eq(existingOrder.baseAssetAmount);
	const isPrimaryPriceEdited =
		+priceBoxStringValue !== +getInitialPrimaryPrice();
	const isSecondaryPriceEdited =
		+secondaryPriceBoxStringValue !== +getInitialSecondaryPrice();

	const priceImpactInfo = useDebouncedValue(
		() =>
			getPriceImpactForTargetMarket(
				{
					marketId,
					side,
					leadSide: 'base',
					baseSize: BigNum.fromPrint(
						baseSizeStringValue,
						AMM_RESERVE_PRECISION_EXP
					).val,
					quoteSize: ZERO,
					uiOrderType: existingOrderType.value,
					isSellPredictionMarket,
				},
				getState,
				getDlobState,
				oraclePrice
			),
		1000
	);

	const { entryPrice, priceImpact } = priceImpactInfo;
	const entryPriceBigNum = new BigNum(entryPrice, QUOTE_PRECISION_EXP);
	const priceImpactBigNum = new BigNum(priceImpact, PERCENTAGE_PRECISION_EXP);

	const priceStepIncrement = UI_UTILS.getInputPriceStepIncrement(
		markPriceToUse.toNum()
	);

	// Order quote value
	const quotePriceToUse = getQuotePriceToUse(
		existingOrderType.value,
		(isSpot ? existingOrderDirectionSpot : existingOrderDirectionPerp) as
			| 'long'
			| 'short'
			| 'buy'
			| 'sell',
		markPriceToUse,
		oraclePrice,
		priceBoxValueBigNum,
		markPriceToUse,
		secondaryPriceBoxValueBigNum
	);
	const orderSizeQuoteValueBigNum = quotePriceToUse
		.mul(baseSizeBigNum)
		.shiftTo(PRICE_PRECISION_EXP);

	// Max base available + existing order size (should be available after cancel)
	const { maxBaseAvailable } = useMaxAvailable({
		marketIndex,
		isSpot,
		side,
		entryPrice: isMarketOnlyOrder
			? entryPriceBigNum?.toNum()
			: isMarketTpSlOrder
			? +priceBoxStringValue
			: isOracleOffsetOrder
			? oraclePrice?.toNum()
			: +limitPriceStringValue,
	});
	const maxBaseAfterCancel =
		maxBaseAvailable + Number(existingOrderSizeStringValue);

	const maxBaseBigNum = existingOrder.reduceOnly
		? getReduceOnlyMax()
		: BigNum.fromPrint(
				`${maxBaseAfterCancel === Infinity ? 0 : maxBaseAfterCancel}`,
				BASE_PRECISION_EXP
		  ).abs();
	const maxBaseSizeString = UI_UTILS.roundToStepSizeIfLargeEnough(
		maxBaseBigNum.toNum().toString(),
		stepSize
	);
	const maxBaseRoundedToStepSizeBigNum = BigNum.fromPrint(
		maxBaseSizeString,
		BASE_PRECISION_EXP
	);

	const maxBaseRef = useRef(BigNum.zero());
	maxBaseRef.current = maxBaseRoundedToStepSizeBigNum; // I have no idea why this workaround is needed, but for some reason maxBaseRoundedToStepSizeBigNum was not updated inside handleBaseSizeBoxUpdate

	const errorMessageInfo = getMessageInfoForOrderViolation(
		existingOrderType.value,
		isSpot,
		+priceBoxStringValue,
		markPriceToUse.toNum(),
		false,
		+secondaryPriceBoxStringValue,
		orderIsLong ? 'long' : 'short',
		currentPositionSide,
		oraclePrice.toNum(),
		baseAssetSymbol,
		isSellPredictionMarket,
		l2State,
		false,
		baseSizeBigNum.toNum(),
		stepSize
	);

	const canConfirmEditOrder =
		!errorMessageInfo?.disableTradeButton &&
		(isSizeEdited ||
			isPrimaryPriceEdited ||
			isSecondaryPriceEdited ||
			newPrice) &&
		baseSizeBigNum.gtZero() &&
		!priceBoxValueBigNum.eqZero() &&
		(!showSecondaryPriceBox || !secondaryPriceBoxValueBigNum.eqZero()) &&
		!submitting;

	const handleBaseSizeBoxUpdate = (value: string) => {
		lastUserInputMutex.current = 'text';

		const isGreaterThanMaxSize = BigNum.fromPrint(value, BASE_PRECISION_EXP).gt(
			maxBaseRef.current
		);

		if (isGreaterThanMaxSize) {
			const fitToStepSize = UI_UTILS.roundToStepSizeIfLargeEnough(
				maxBaseRef.current.toNum().toString(),
				stepSize
			);
			_setBaseSizeStringValue(fitToStepSize);
		} else {
			const fitToStepSize = UI_UTILS.roundToStepSizeIfLargeEnough(
				value,
				stepSize
			);
			_setBaseSizeStringValue(fitToStepSize);
		}
	};

	const handleSliderValueUpdate = (value: number) => {
		lastUserInputMutex.current = 'slider';
		setSliderPercentage(value);
	};

	const handleConfirmEditOrder = async () => {
		setSubmitting(true);

		const success = await actions.editOpenOrder({
			existingOrder,
			side:
				existingOrderDirectionPerp.toLowerCase() === 'short' ? 'sell' : 'buy',
			oraclePrice: oraclePrice.val,
			newBaseAmount: baseSizeBigNum,
			newLimitPrice: isLimitOnlyOrder
				? isSellPredictionMarket
					? MAX_PREDICTION_PRICE_BIGNUM.sub(priceBoxValueBigNum)
					: priceBoxValueBigNum
				: isTriggerLimitOrder
				? secondaryPriceBoxValueBigNum
				: BigNum.zero(),
			newOrderType: existingOrderType,
			newTriggerPrice: isTriggerOrder ? priceBoxValueBigNum : BigNum.zero(),
			priceImpactInfo,
			slippageTolerance: getSlippageForMarket(
				marketId,
				slippageTolerance,
				existingOrderDirectionPerp.toLowerCase() === 'short'
					? PositionDirection.SHORT
					: PositionDirection.LONG,
				priceImpactInfo
			),
			newOraclePriceOffset: isOracleOffsetOrder
				? Number(priceBoxStringValue)
				: undefined,
		});

		if (success) {
			onClose();
		} else {
			// If it fails, the user will get a toast notification from the editOpenOrder action
			setSubmitting(false);
		}
	};

	const handleCancel = async () => {
		setSubmitting(true);

		try {
			await actions.cancelOrder(existingOrder.orderId);
		} catch (err) {
			console.log(err);
			// do nothing
		}

		onClose();
	};

	function getReduceOnlyMax(): BigNum {
		if (isSpot) {
			const currentPositionDeposit = currentUserSpotBalances.find(
				(bal) =>
					bal.asset.marketIndex === existingOrder.marketIndex &&
					matchEnum(bal.balanceType, SpotBalanceType.DEPOSIT)
			)?.balance;

			const currentPositionBorrow = currentUserSpotBalances.find(
				(bal) =>
					bal.asset.marketIndex === existingOrder.marketIndex &&
					matchEnum(bal.balanceType, SpotBalanceType.BORROW)
			)?.balance;

			if (
				(!currentPositionBorrow && !currentPositionDeposit) ||
				(currentPositionBorrow?.eqZero() && currentPositionDeposit?.eqZero())
			) {
				return BigNum.zero(existingOrder.baseAssetAmount.precision);
			} else if (existingOrderDirectionSpot.toLowerCase() === 'sell') {
				return currentPositionDeposit?.abs();
			} else {
				return (
					currentPositionBorrow?.abs() ??
					BigNum.zero(existingOrder.baseAssetAmount.precision)
				);
			}
		} else {
			const currentPerpPositionSize: BN = currentUserPerpPositions.find(
				(pos) => pos.marketIndex === existingOrder.marketIndex
			)?.baseSize;

			if (!currentPerpPositionSize) return BigNum.zero(BASE_PRECISION_EXP);

			if (existingOrderDirectionPerp.toLowerCase() === 'short') {
				return currentPerpPositionSize.isNeg()
					? BigNum.zero(BASE_PRECISION_EXP)
					: BigNum.from(currentPerpPositionSize, BASE_PRECISION_EXP)?.abs();
			} else {
				return currentPerpPositionSize.isNeg()
					? BigNum.from(currentPerpPositionSize, BASE_PRECISION_EXP)?.abs()
					: BigNum.zero(BASE_PRECISION_EXP);
			}
		}
	}

	// updates both limit and trigger price boxes (if applicable) when new price (from TV chart) changes
	useEffect(() => {
		if (newPrice && !newPrice.eqZero()) {
			if (isLimitOnlyOrder) {
				setPriceBoxStringValue(newPrice.print());
			}

			if (isTriggerLimitOrder) {
				setPriceBoxStringValue(newPrice.print());
				setSecondaryPriceBoxStringValue(newPrice.print());
			}
		}
	}, [newPrice?.print()]);

	// updates slider when base size input changes
	useEffect(() => {
		if (lastUserInputMutex.current === 'slider' || maxBaseSizeString === '0') {
			return;
		}

		const updatedSliderPercentage = +BigNum.fromPrint(
			baseSizeStringValue,
			BASE_PRECISION_EXP
		).toPercentage(maxBaseBigNum, BASE_PRECISION_EXP.toNumber());

		if (updatedSliderPercentage < 0) {
			setSliderPercentage(0);
			return;
		} else if (updatedSliderPercentage > 99.99) {
			setSliderPercentage(100);
			return;
		}

		setSliderPercentage(updatedSliderPercentage);
	}, [baseSizeStringValue, maxBaseSizeString]);

	// updates base size input when slider changes
	useEffect(() => {
		if (lastUserInputMutex.current === 'text') {
			return;
		}

		const scaledBaseSize = maxBaseRoundedToStepSizeBigNum.scale(
			sliderPercentage,
			100
		);

		_setBaseSizeStringValue(
			UI_UTILS.roundToStepSizeIfLargeEnough(
				scaledBaseSize.abs().toNum().toString(),
				stepSize
			)
		);
	}, [sliderPercentage, stepSize, maxBaseSizeString]);

	useEffect(() => {
		if (+maxBaseSizeString < +baseSizeStringValue && existingOrder.reduceOnly) {
			handleBaseSizeBoxUpdate(maxBaseSizeString);
		}
	}, [maxBaseSizeString]);

	return (
		<div className="flex flex-col [&>div]:p-3 w-full sm:w-[360px]">
			<EditOrderHeader
				uiMarket={uiMarket}
				price={isOracleOffsetOrder ? oraclePrice : markPriceToUse}
				isMarkPrice={!isOracleOffsetOrder}
				marketSymbol={uiMarket.symbol}
				size={baseSizeBigNum}
				headerText={`Edit ${existingOrderType.label} Order`}
				onClose={onClose}
				stepSize={stepSize}
				isLongOrder={existingOrderDirectionPerp.toLowerCase() === 'long'}
			/>

			<div className="flex flex-col w-full">
				{/** Form inputs */}
				<div className="flex flex-col gap-3">
					{/** Order Prices Row */}
					<div className="flex items-center gap-2">
						{/** Limit -> Limit | Stop/Stop Limit/Take Profit/Take Profit Limit -> Trigger Oracle Price | Oracle Limit -> Oracle Price Offset */}
						{existingOrderType.value !== 'market' && (
							<FormInputBox label={priceBoxHeading}>
								<TextField.Default
									value={priceBoxStringValue}
									type="number"
									onChange={setPriceBoxStringValue}
									suffix={'USD'}
									showIconForMarketSymbol={'USDC'}
									stepAmount={priceStepIncrement}
								/>
							</FormInputBox>
						)}

						{showSecondaryPriceBox && (
							<FormInputBox label="Limit Price">
								<TextField.Default
									value={secondaryPriceBoxStringValue}
									type="number"
									onChange={setSecondaryPriceBoxStringValue}
									suffix={'USD'}
									showIconForMarketSymbol={'USDC'}
									stepAmount={priceStepIncrement}
								/>
							</FormInputBox>
						)}
					</div>

					{/** Size Fields Row */}
					<div className="flex items-center gap-2">
						<FormInputBox label="Size">
							<TextField.Default
								value={baseSizeStringValue}
								type="number"
								onChange={handleBaseSizeBoxUpdate}
								showIconForMarketSymbol={uiMarket.symbol}
							/>
						</FormInputBox>
						<FormInputBox
							quickAction={
								<div className="flex justify-end">
									<Text.BODY3
										className="flex justify-center px-1 rounded-sm bg-button-secondary-bg item-center text-text-secondary-button py-[2px] cursor-pointer"
										onClick={() => handleBaseSizeBoxUpdate(maxBaseSizeString)}
									>
										Max: {maxBaseSizeString}{' '}
										{uiMarket.isPredictionMarket ? 'shares' : baseAssetSymbol}
									</Text.BODY3>
								</div>
							}
						>
							<TextField.Default
								value={orderSizeQuoteValueBigNum.print()}
								type="number"
								disabled
								onChange={() => {}}
								showIconForMarketSymbol={'USDC'}
							/>
						</FormInputBox>
					</div>
				</div>
			</div>

			{/** Leverage Slider */}
			<div className="w-full text-text-emphasis">
				<Slider
					type="percent"
					disabled={null}
					value={sliderPercentage}
					onDrop={handleSliderValueUpdate}
					onMove={handleSliderValueUpdate}
					step={1}
					maxButtonTransition={false}
				/>
			</div>

			{existingOrderType.value === 'market' && <SlippageToleranceDisplay />}

			<div className="pt-4 border border-b-0 border-t-1 border-x-0 border-container-border">
				<div className="mb-2 font-bold">
					<Text.BODY2>Changes to Order:</Text.BODY2>
				</div>

				<div className="flex items-center justify-between w-full space-x-6 text-xs text-text-default">
					<div className="text-text-default">Side</div>
					<Text.BODY3
						className={twMerge(
							`font-numeral`,
							ENUM_UTILS.match(existingOrder.direction, PositionDirection.LONG)
								? 'text-positive-green'
								: 'text-negative-red'
						)}
					>
						{isSpot
							? existingOrderDirectionSpot
							: uiMarket.isPredictionMarket
							? existingOrderDirectionPerp.toLowerCase() === 'long'
								? 'Bet Yes'
								: 'Bet No'
							: existingOrderDirectionPerp}
					</Text.BODY3>
				</div>

				<div className="flex items-center justify-between w-full space-x-6 text-xs text-text-default">
					<div className="text-text-default">Order Size</div>
					<div
						className={`text-text-default font-numeral ${
							Number(existingOrderSizeStringValue) !==
							Number(baseSizeStringValue)
								? ' text-text-emphasis font-bold'
								: ''
						}`}
					>
						<ValueDisplay.ValueChange
							label=""
							previousValue={Number(existingOrderSizeStringValue)}
							afterValue={baseSizeBigNum.toNum()}
							previousValuePrint={UI_UTILS.roundToStepSizeIfLargeEnough(
								existingOrderSizeStringValue,
								stepSize
							)}
							afterValuePrint={UI_UTILS.roundToStepSizeIfLargeEnough(
								baseSizeBigNum.toNum().toString(),
								stepSize
							)}
							forceWhite
						/>
					</div>
				</div>

				{isAnyLimitOrder && (
					<div className="flex items-center justify-between w-full space-x-6 text-xs text-text-default">
						<div className="text-text-default">Limit Price</div>
						<div
							className={twMerge(
								`text-text-default font-numeral`,
								((isLimitOnlyOrder && isPrimaryPriceEdited) ||
									(isTriggerLimitOrder && isSecondaryPriceEdited)) &&
									'text-text-emphasis font-bold'
							)}
						>
							<ValueDisplay.ValueChange
								label=""
								previousValue={Number(existingOrderLimitPriceString)}
								afterValue={
									+(isTriggerLimitOrder
										? secondaryPriceBoxStringValue
										: priceBoxStringValue)
								}
								previousValuePrint={`$${existingOrderLimitPriceString}`}
								afterValuePrint={`$${(isTriggerLimitOrder
									? secondaryPriceBoxValueBigNum
									: priceBoxValueBigNum
								).toFixed(4)}`}
								forceWhite
							/>
						</div>
					</div>
				)}

				{isTriggerOrder && (
					<div className="flex items-center justify-between w-full space-x-6 text-xs text-text-default">
						<div className="text-text-default">Trigger Oracle Price</div>
						<div
							className={twMerge(
								`text-text-default font-numeral`,
								isPrimaryPriceEdited && 'text-text-emphasis font-bold'
							)}
						>
							<ValueDisplay.ValueChange
								label=""
								previousValue={Number(existingOrderTriggerPriceString)}
								afterValue={Number(priceBoxStringValue)}
								previousValuePrint={existingOrderTriggerPriceString}
								afterValuePrint={priceBoxValueBigNum.toFixed(4)}
								forceWhite
							/>
						</div>
					</div>
				)}

				{isOracleOffsetOrder && (
					<div className="flex items-center justify-between w-full space-x-6 text-xs text-text-default">
						<div className="text-text-default">Oracle Offset</div>
						<div
							className={twMerge(
								`text-text-default font-numeral`,
								isPrimaryPriceEdited && 'text-text-emphasis font-bold'
							)}
						>
							<ValueDisplay.ValueChange
								label=""
								previousValue={Number(existingOrderOracleOffset)}
								afterValue={Number(priceBoxStringValue)}
								previousValuePrint={existingOrderOracleOffset}
								afterValuePrint={priceBoxValueBigNum.toFixed(4)}
								forceWhite
							/>
						</div>
					</div>
				)}

				{existingOrderType.value === 'market' && (
					<div>
						<div className="flex items-center justify-between w-full space-x-6 text-xs text-text-default">
							<div className="text-text-default">Est. Entry Price</div>
							<div className={'font-numeral text-text-emphasis font-bold'}>
								<ValueDisplay.Default
									label={''}
									value={
										baseSizeStringValue &&
										Number(baseSizeStringValue) > 0 &&
										entryPriceBigNum &&
										entryPriceBigNum.gtZero() ? (
											<>
												{'$'}
												{entryPriceBigNum.toFixed(2)}
											</>
										) : (
											'-'
										)
									}
								/>
							</div>
						</div>
						<div className="flex items-center justify-between w-full space-x-6 text-xs text-text-default">
							<div className="text-text-default">Est. Price Impact</div>
							<div className={'font-numeral text-text-emphasis font-bold'}>
								<ValueDisplay.Default
									label={''}
									value={
										baseSizeStringValue &&
										Number(baseSizeStringValue) > 0 &&
										priceImpactBigNum &&
										priceImpactBigNum.gtZero() ? (
											<>
												{priceImpactBigNum.scale(100, 1).toFixed(2)}
												{'%'}
											</>
										) : (
											'-'
										)
									}
								/>
							</div>
						</div>
					</div>
				)}
			</div>

			<div className="flex flex-col space-y-2 min-h-[36px]">
				{errorMessageInfo?.shouldShowMessage && (
					<div className="flex flex-col max-w-[320px]">
						<InfoMessage
							type={errorMessageInfo.messageType}
							messageTitle={errorMessageInfo.messageTitle}
						/>
					</div>
				)}
			</div>

			<div className="flex flex-row items-center space-x-2">
				<Button.Secondary
					size="MEDIUM"
					negativeRed
					onClick={handleCancel}
					disabled={submitting}
					className="w-full"
				>
					Cancel Order
				</Button.Secondary>
				<Button.Primary
					positiveGreen
					disabled={!canConfirmEditOrder}
					onClick={handleConfirmEditOrder}
					size="MEDIUM"
					className="w-full hover:opacity-70"
				>
					Confirm
				</Button.Primary>
			</div>
		</div>
	);
};

export default React.memo(EditOrderPopup);
