'use client';

import { Info } from '@drift-labs/icons';
import React from 'react';
import { ShowLiquidityWarningState } from '../hooks/useShowLiquidityWarnings';
import Tooltip from './Tooltip/Tooltip';
import ValueDisplay from './ValueDisplay';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';

const TradeformLiquidityWarning = ({
	warningState,
}: {
	warningState: ShowLiquidityWarningState;
}) => {
	const isMobile = useIsMobileScreenSize();
	const bidSideLabel = warningState.side === 'ask' ? 'Ask' : 'Bid';

	// temporarily disabling this. half our fills are JIT now so this is a little misleading.
	// perhaps we can add it back with some changed verbiage like "minimum received". working with Z on details here
	return;
	return (
		<Tooltip
			allowHover
			showArrow={!isMobile}
			placement={isMobile ? 'top' : 'right'}
			content={
				<>
					<div>
						<span>
							There is estimated to be less {bidSideLabel} liquidity than the
							size of your current order. This can create bad slippage.
						</span>
					</div>
				</>
			}
		>
			<ValueDisplay.Default
				label={
					<span>
						<span>Avail. {bidSideLabel} Liquidity</span>
						<Info size={12} className="relative top-0.5 left-1" />
					</span>
				}
				value={`${warningState.value}`}
				warn
				rightPadding={'pt-[2px]'}
			/>
		</Tooltip>
	);
};

export default TradeformLiquidityWarning;
