import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { SwiftEnablementStatus } from 'src/@types/signedMsgOrders';
import { useMemo } from 'react';
import useFeatureFlagStore from 'src/stores/useFeatureFlagStore';
import { useSwiftServerHealthCheck } from '../swift/useSwiftServerHealthcheck';
import useAccountData from '../useAccountData';

/**
 * Hook to determine whether to show the Signed Message Orders switch on the trade form and its enabled state
 * @returns {SwiftEnablementStatus} The Signed Message Orders switch enablement status:
 * - {status: 'hidden'} Signed Message Orders switch should not be shown
 * - {status: 'disabled'; reason: string} Signed Message Orders switch should be shown but disabled
 * - {status: 'enabled'} Signed Message Orders switch should be shown and enabled for toggling
 * NOTE: In the future, we will potentially have to use the user's wallet to determine the enablement status
 */
export const useSignedMsgOrdersEnablementStatus = (): SwiftEnablementStatus => {
	const { tradeForm } = useDriftStore((s) => s);
	const isSignedMsgOrdersEnabled = useFeatureFlagStore((s) =>
		s.flagEnabled('ENABLE_SIGNED_MSG_ORDERS')
	);
	const isDelegateAccount = useAccountData()?.isDelegatedTo ?? false;
	const isSwiftServerHealthy = useSwiftServerHealthCheck();
	const showSwiftSwitch =
		isSignedMsgOrdersEnabled &&
		(tradeForm.orderType === 'market' || tradeForm.orderType === 'limit') &&
		isSwiftServerHealthy;

	const result = useMemo<SwiftEnablementStatus>(() => {
		if (isDelegateAccount) {
			return { status: 'hidden' };
		}

		if (!showSwiftSwitch) {
			return { status: 'hidden' };
		}

		if (!isSwiftServerHealthy) {
			return {
				status: 'disabled',
				reason: 'Swift is currently not available.',
			};
		}

		if (tradeForm.postOnly) {
			return {
				status: 'disabled',
				reason:
					'Post-only orders do not go through an auction so are not supported via Swift',
			};
		}

		return { status: 'enabled' };
	}, [
		showSwiftSwitch,
		isSwiftServerHealthy,
		tradeForm.postOnly,
		isDelegateAccount,
	]);

	return result;
};
